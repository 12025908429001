<template>
    <div id="Project">
        <FloatingOctave></FloatingOctave>
        <div class="main" v-if="projectData.projectTitle">
            <div
                class="section section-intro"
                id="section-intro"
                name="section-intro"
            >
                <div class="wrap">
                    <div
                        class="title"
                        data-aos="fade-up"
                        :data-aos-once="true"
                        data-aos-anchor="#section-intro"
                    >
                        <h1>{{ projectData.projectTitle }}</h1>
                        <a
                            v-if="projectData.websiteURL"
                            :href="projectData.websiteURL"
                            target="_blank"
                        >
                            網站連結
                        </a>
                        <a
                            v-if="projectData.iosURL"
                            :href="projectData.iosURL"
                            target="_blank"
                        >
                            iOS 下載
                        </a>
                        <a
                            v-if="projectData.androidURL"
                            :href="projectData.androidURL"
                            target="_blank"
                        >
                            Android 下載
                        </a>
                        <span
                            v-if="
                                false &&
                                projectData.pictureURLs &&
                                projectData.pictureURLs.length
                            "
                        >
                            設計預覽
                        </span>
                    </div>
                    <div
                        class="intro"
                        data-aos="fade-up"
                        :data-aos-once="true"
                        data-aos-delay="200"
                        data-aos-anchor="#section-intro"
                    >
                        <h3>INTRO</h3>
                        <ul
                            v-for="(item, index) in projectData.intro"
                            :key="'Intro' + index"
                        >
                            <li>{{ item }}</li>
                        </ul>
                    </div>
                    <img
                        class="picture"
                        data-aos="fade-up"
                        :data-aos-once="true"
                        data-aos-delay="600"
                        data-aos-anchor="#section-intro"
                        :src="projectData.pictureURL"
                    />
                    <div
                        class="features"
                        data-aos="fade-up"
                        :data-aos-once="true"
                        data-aos-delay="400"
                        data-aos-anchor="#section-intro"
                    >
                        <h3>FEATURES</h3>
                        <ul
                            v-for="(item, index) in projectData.features"
                            :key="'Features' + index"
                        >
                            <li>{{ item }}</li>
                        </ul>
                    </div>
                </div>
            </div>

            <div
                class="section section-functions"
                id="section-functions"
                name="section-functions"
            >
                <div class="wrap">
                    <div class="octave-roles">
                        <h3
                            data-aos="fade-up"
                            id="octave-roles"
                            name="octave-roles"
                        >
                            OCTAVE ROLES
                        </h3>
                        <ul>
                            <li
                                v-for="(item, index) in projectData.octaveRoles"
                                :key="'role' + index"
                                data-aos="fade-up"
                                :data-aos-delay="index * 100"
                                data-aos-anchor="#octave-roles"
                            >
                                <i
                                    :class="['iconfont', 'icon-' + item.icon]"
                                ></i>
                                <span class="ch">{{ item.ch }}</span>
                                <span class="en">{{ item.en }}</span>
                            </li>
                        </ul>
                    </div>
                    <div class="special-functions">
                        <h3
                            data-aos="fade-up"
                            data-aos-delay="300"
                            id="special-functions"
                            name="special-functions"
                        >
                            SPECIAL FUNCTIONS
                        </h3>
                        <ul>
                            <li
                                v-for="(
                                    item, index
                                ) in projectData.specialFunctions"
                                :key="'function' + index"
                                data-aos="fade-up"
                                :data-aos-delay="300 + index * 100"
                                data-aos-anchor="#special-functions"
                            >
                                {{ item }}
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div
                class="section section-statistics"
                id="section-statistics"
                name="section-statistics"
            >
                <div class="wrap">
                    <div class="statistics">
                        <h3
                            data-aos="fade-up"
                            id="statistics"
                            name="statistics"
                        >
                            STATISTICS
                        </h3>
                        <ul>
                            <li
                                v-for="(item, index) in projectData.statistics"
                                :key="'Statistics' + index"
                                data-aos="flip-left"
                                :data-aos-delay="index * 100"
                                data-aos-anchor="#statistics"
                            >
                                <span class="title">{{ item.title }}</span>
                                <span class="projectData">{{ item.data }}</span>
                                <span class="unit">{{ item.unit }}</span>
                            </li>
                        </ul>
                    </div>
                    <div class="system-specification">
                        <h3
                            data-aos="fade-up"
                            data-aos-delay="300"
                            id="system-specification"
                        >
                            SYSTEM SPECIFICATION
                        </h3>
                        <ul>
                            <li
                                v-for="(
                                    item, index
                                ) in projectData.systemSpecification"
                                :key="'spec' + index"
                                data-aos="zoom-out"
                                :data-aos-delay="300 + index * 100"
                                data-aos-anchor="#system-specification"
                            >
                                {{ item }}
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div
                class="section section-diagram"
                id="section-diagram"
                name="section-diagram"
                v-if="projectData.systemDiagramURL"
            >
                <div class="wrap">
                    <h3
                        data-aos="fade-up"
                        id="system-diagram"
                        name="system-diagram"
                    >
                        SYSTEM DIAGRAM
                    </h3>
                    <img
                        class="system-diagram"
                        data-aos="fade-up"
                        :data-aos-delay="300"
                        :src="projectData.systemDiagramURL"
                        data-aos-anchor="#system-diagram"
                    />
                </div>
            </div>
        </div>
        <ContactForm v-if="projectData.projectTitle"></ContactForm>
    </div>
</template>

<script>
import { onMounted, reactive } from 'vue-demi'
import { useRoute } from 'vue-router'
import { getProjectDetailAPI } from '../../service/api'
import { ContactForm, FloatingOctave } from '../../components/index.js'
export default {
    name: 'Project',
    components: { ContactForm, FloatingOctave },

    setup() {
        let projectData = reactive({})
        let route = useRoute()

        onMounted(async () => {
            await getProjectDetailAPI(route.params.projectTitle).then((res) => {
                Object.keys(res.data).forEach((key) => {
                    projectData[key] = res.data[key]
                })
            })
        })

        return { projectData }
    },
}
</script>

<style lang="scss" scoped>
$border-setting: 3px solid $gray-4;

@mixin mainFontSetting() {
    font-size: 18px;
    font-weight: 700;
    letter-spacing: 0.05em;
}
@mixin mainListSetting() {
    display: grid;
    row-gap: 30px;
    width: fit-content;
}

.main {
    position: relative;

    .section {
        position: relative;
        .wrap {
            position: relative;
            margin: 0 auto;
        }
    }
}

.section-intro {
    h1 {
        @include h1Setting($major-dark, true);
    }
    h3 {
        @include h3Setting($gray-4);
    }
    .title {
        a,
        span {
            display: inline-block;
            margin: 0 10px 0 0;
            text-decoration: none;
            @include buttonSetting($major-dark);
        }
    }
    .picture,
    .intro,
    .features {
        border: $border-setting;
    }
    .intro,
    .features {
        position: relative;
        z-index: 50;
        padding: 25px 30px 20px;
        background-color: $translucent;
        h3 {
            position: absolute;
            padding: 10px;
            background-color: white;
            border-radius: 50px;
        }
        ul {
            @include pSetting();
        }
    }
}

.section-functions {
    padding: 80px 0 90px;
    background-color: $gray-2;
    h3 {
        @include h3Setting($major-light, true);
    }
    ul {
        margin: 50px auto 0;
        color: $gray-f;
        @include mainListSetting();
    }
    .octave-roles {
        li {
            display: grid;
            grid-template: 24px 16px/ 40px 200px;
            width: fit-content;
        }
        i.iconfont {
            grid-area: 1 / 1 / 3 / 2;
            font-size: 24px;
            line-height: 40px;
        }
        span.ch {
            font-size: 18px;
            font-weight: 700;
            letter-spacing: 0.05em;
        }
        span.en {
            font-size: 14px;
            font-weight: 500;
            letter-spacing: 0.02em;
        }
    }
    .special-functions {
        li {
            list-style-type: disc;
            font-size: 18px;
            font-weight: 700;
            letter-spacing: 0.05em;
        }
    }
}

.section-statistics {
    h3 {
        @include h3Setting($gray-4, true);
    }
    ul {
        margin: 50px auto 0;
        color: $gray-4;
    }
    .statistics {
        ul {
            display: grid;
            grid-template: repeat(2, 1fr) / repeat(2, 1fr);
            grid-gap: 10px 15px;

            width: fit-content;
        }
        li {
            padding: 20px;
            box-sizing: border-box;
            text-align: center;
            background-color: $translucent;
            border: $border-setting;
        }
        .title {
            @include mainFontSetting();
            display: block;
            margin-bottom: 1em;
        }
        .projectData {
            margin-right: 10px;
            font-size: 48px;
            font-weight: 800;
        }
        .unit {
            font-size: 16px;
            font-weight: 500;
        }
    }
    .system-specification {
        ul {
            @include mainListSetting();
        }
        li {
            list-style-type: disc;
            @include mainFontSetting();
        }
    }
}

.section-diagram {
    padding: 80px 0 50px;
    background-color: $major-dark;

    h3 {
        margin-bottom: 50px;
        @include h3Setting($gray-f, true);
    }
    .system-diagram {
        width: 100%;
    }
}
</style>

<style lang="scss" scoped>
@media screen and (min-width: 640px) {
    .wrap {
        width: 80%;
        max-width: 1200px;
    }
    .section-intro {
        overflow: hidden;
        margin-bottom: 0;
        padding-bottom: 80px;
        .wrap {
            div {
                margin-bottom: 100px;
            }
            .title {
                margin-bottom: 120px;
                h1 {
                    text-align: left;
                }
            }
            .picture {
                position: absolute;
                top: 20vh;
                right: 0;
                width: 50vw;
                min-width: 480px;
            }
            .intro,
            .features {
                width: 30vw;
                min-width: 288px;

                h3 {
                    left: 20px;
                    top: -20px;
                }
            }

            .features {
                margin-left: 10vw;
            }
        }
    }

    .section-functions {
        margin-top: 0;
    }

    .section-functions,
    .section-statistics {
        .wrap {
            display: grid;
            grid-template-columns: repeat(2, auto);
            column-gap: 50px;
        }
    }
}

@media screen and (max-width: 639px) {
    .wrap {
        width: calc(100% - 20px);
    }

    .section-intro {
        .title {
            text-align: center;
        }
        .intro,
        .features {
            margin: 50px 0;
            h3 {
                top: -20px;
            }
        }
        .picture {
            display: none;
        }
    }

    .section-functions,
    .section-statistics {
        .wrap > div {
            margin-top: 100px;
            &:first-child {
                margin-top: 0;
            }
        }
    }

    .section-statistics {
        .statistics {
            ul {
                grid-gap: 7px;
            }
            li {
                .title {
                    font-size: 16px;
                }
                .caseData {
                    font-size: 40px;
                    margin-right: 6px;
                }
                .unit {
                    font-size: 14px;
                }
            }
        }
    }
}
</style>
